<template>
    <div>
        <div class="text-center my-5">
            <img class="mb-3" :src="Logo" alt="logo" width="100" />
            <h3 class="font-weight-bold m-0">PEMERINTAH PROVINSI SUMATERA UTARA</h3>
            <h5 class="font-weight-bold m-0">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</h5>
            <h5 class="mb-1">BIDANG RETRIBUSI DAN PENDAPATAN LAINNYA</h5>
            <div class="font-weight-bold text-muted" style="font-size: 11px">
                Jl. Sisingamangaraja Km 5,5 Medan
            </div>
        </div>
        <div class="pb-5">
            <div class="container-fluid mb-3">
                <b-row>
                    <b-col offset="2" md="8">
                        <transition name="slide-fade">
                            <b-card v-if="selesai">
                                <div class="text-center">
                                    <i
                                        class="fa fa-check-circle fa-8x text-success"
                                    ></i>
                                    <h1 class="text-success">Berhasil</h1>
                                    <h5 class="row">
                                        <b-col offset="3" md="6">
                                            Terima kasih telah mendaftarkan diri
                                            anda di aplikasi E-RETRIBUSI. User
                                            login anda akan dikirimkan melalui
                                            email anda.
                                        </b-col>
                                    </h5>
                                    <div class="my-3">
                                        <b-button variant="success" @click="login" size="sm">
                                            Klik disini untuk ke halaman login.
                                        </b-button>
                                    </div>
                                </div>
                            </b-card>
                            <b-card class="shadow" v-else>
                                <h1>Form Registrasi Data Pegawai</h1>
                                <div>
                                    Lengkapi data di bawah ini untuk
                                    mendaftarkan pegawai.
                                </div>
                                <form
                                    @submit.prevent="store"
                                    @keydown="form.onKeydown($event)"
                                    autocomplete="off"
                                    class="my-3"
                                >
                                    <h4>DATA DIRI PEGAWAI</h4>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >PERANGKAT
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-md-7">
                                            <v-select
                                                label="nama"
                                                :options="
                                                    PerangkatReg.perangkat
                                                "
                                                :reduce="nama => nama.id"
                                                v-model="
                                                    perangkat.perangkat_pd_id
                                                "
                                                @input="
                                                    inputPerangkat(
                                                        perangkat.perangkat_pd_id
                                                    )
                                                "
                                                placeholder="Pilih Perangkat"
                                            >
                                            </v-select>
                                            <em
                                                v-if="
                                                    form.errors.has(
                                                        'perangkat_pd_id'
                                                    )
                                                "
                                                class="form-text text-danger"
                                                >{{
                                                    errors.perangkat_pd_id[0]
                                                }}</em
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >UNIT
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-md-7">
                                            <v-select
                                                label="nama"
                                                :options="PerangkatReg.unit"
                                                :reduce="nama => nama.id"
                                                v-model="form.unit_pd_id"
                                                placeholder="Pilih Unit"
                                            >
                                            </v-select>
                                            <em
                                                v-if="
                                                    form.errors.has(
                                                        'unit_pd_id'
                                                    )
                                                "
                                                class="form-text text-danger"
                                                >{{ errors.unit_pd_id[0] }}</em
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >NIK
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'nik'
                                                            )
                                                        }"
                                                        v-model="form.nik"
                                                        placeholder="Masukkan NIK"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'nik'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{ errors.nik[0] }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >NIP
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'nip'
                                                            )
                                                        }"
                                                        v-model="form.nip"
                                                        placeholder="Masukkan NIP"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'nip'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{ errors.nip[0] }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Nama
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'nama'
                                                            )
                                                        }"
                                                        v-model="form.nama"
                                                        placeholder="Masukkan Nama"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'nama'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.nama[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Jenis Kelamin
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div
                                                        class="radio radio-css radio-inline"
                                                    >
                                                        <input
                                                            type="radio"
                                                            id="inlineCssRadio1"
                                                            v-model="
                                                                form.jenis_kelamin
                                                            "
                                                            value="laki-laki"
                                                        />
                                                        <label
                                                            for="inlineCssRadio1"
                                                            >Laki-laki</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="radio radio-css radio-inline"
                                                    >
                                                        <input
                                                            type="radio"
                                                            id="inlineCssRadio2"
                                                            v-model="
                                                                form.jenis_kelamin
                                                            "
                                                            value="perempuan"
                                                        />
                                                        <label
                                                            for="inlineCssRadio2"
                                                            >Perempuan</label
                                                        >
                                                    </div>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'jenis_kelamin'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors
                                                                .jenis_kelamin[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Tempat Lahir
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'tmp_lahir'
                                                            )
                                                        }"
                                                        v-model="form.tmp_lahir"
                                                        placeholder="Masukkan Tempat Lahir"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'tmp_lahir'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.tmp_lahir[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Tanggal Lahir
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="input-group">
                                                        <date-picker
                                                            id="dateTimeInput"
                                                            v-model="
                                                                form.tgl_lahir
                                                            "
                                                            :config="options"
                                                            placeholder="-Pilih Tanggal-"
                                                        >
                                                        </date-picker>
                                                        <div
                                                            class="input-group-append"
                                                        >
                                                            <label
                                                                for="dateTimeInput"
                                                                class="input-group-text"
                                                            >
                                                                <i
                                                                    class="fa fa-calendar"
                                                                ></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'tgl_lahir'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.tgl_lahir[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-5">
                                        <h4>KONTAK & ALAMAT</h4>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Nomor Handphone
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'nomp'
                                                            )
                                                        }"
                                                        v-model="form.nomp"
                                                        placeholder="Masukkan Nomor Handphone"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'nomp'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.nomp[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Email
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="email"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'email'
                                                            )
                                                        }"
                                                        v-model="form.email"
                                                        placeholder="Masukkan Email"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'email'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.email[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Alamat
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'alamat'
                                                            )
                                                        }"
                                                        v-model="form.alamat"
                                                        placeholder="Masukkan Alamat"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'alamat'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.alamat[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Provinsi
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <v-select
                                                        label="nama"
                                                        :options="
                                                            WilayahReg.provinsi
                                                        "
                                                        :reduce="
                                                            nama => nama.id
                                                        "
                                                        v-model="
                                                            wilayah.provinsi
                                                        "
                                                        @input="
                                                            onProvinsi(
                                                                wilayah.provinsi
                                                            )
                                                        "
                                                        placeholder="Pilih Provinsi"
                                                    >
                                                    </v-select>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'wilayah_kelurahan_id'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >Isian Provinsi harus
                                                        diisi.</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Kab./Kota
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <v-select
                                                        label="nama"
                                                        :options="
                                                            WilayahReg.kabkota
                                                        "
                                                        :reduce="
                                                            nama => nama.id
                                                        "
                                                        v-model="
                                                            wilayah.kabkota
                                                        "
                                                        @input="
                                                            onKabkota(
                                                                wilayah.kabkota
                                                            )
                                                        "
                                                        placeholder="Pilih Kab./Kota"
                                                    >
                                                    </v-select>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'wilayah_kelurahan_id'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >Isian Kab./Kota wajib
                                                        diisi</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Kecamatan
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <v-select
                                                        label="nama"
                                                        :options="
                                                            WilayahReg.kecamatan
                                                        "
                                                        :reduce="
                                                            nama => nama.id
                                                        "
                                                        v-model="
                                                            wilayah.kecamatan
                                                        "
                                                        @input="
                                                            onKecamatan(
                                                                wilayah.kecamatan
                                                            )
                                                        "
                                                        placeholder="Pilih Kecamatan"
                                                    >
                                                    </v-select>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'wilayah_kelurahan_id'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >Isian Kecamatan wajib
                                                        diisi</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Kelurahan
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <v-select
                                                        label="nama"
                                                        :options="
                                                            WilayahReg.kelurahan
                                                        "
                                                        :reduce="
                                                            nama => nama.id
                                                        "
                                                        v-model="
                                                            form.wilayah_kelurahan_id
                                                        "
                                                        placeholder="Pilih Kelurahan"
                                                    >
                                                    </v-select>
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'wilayah_kelurahan_id'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors
                                                                .wilayah_kelurahan_id[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-3">
                                        <label
                                            class="col-md-3 col-form-label font-weight-bold"
                                            >Kodepos
                                            <span class="text-danger"
                                                >*</span
                                            ></label
                                        >
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input
                                                        type="text"
                                                        :class="{
                                                            'form-control': true,
                                                            'is-invalid': form.errors.has(
                                                                'kodepos'
                                                            )
                                                        }"
                                                        v-model="form.kodepos"
                                                        placeholder="Masukkan Kodepos"
                                                    />
                                                    <em
                                                        v-if="
                                                            form.errors.has(
                                                                'kodepos'
                                                            )
                                                        "
                                                        class="form-text text-danger"
                                                        >{{
                                                            errors.kodepos[0]
                                                        }}</em
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <span>
                                        <router-link
                                            to="/auth/login"
                                            class="font-weight-bold"
                                        >
                                            Kembali ke halaman Login.
                                        </router-link>
                                    </span>
                                    <span class="float-right">
                                        <b-button
                                            variant="success"
                                            type="submit"
                                        >
                                            <span v-if="isLoading">
                                                <b-spinner
                                                    variant="success"
                                                    label="Spinning"
                                                    small
                                                ></b-spinner>
                                            </span>
                                            <i class="fa fa-pen" v-else></i>
                                            Registrasi Pegawai
                                        </b-button>
                                    </span>
                                </form>
                                <b-overlay
                                    :show="show"
                                    no-wrap
                                    spinner-variant="primary"
                                ></b-overlay>
                            </b-card>
                        </transition>
                    </b-col>
                </b-row>
            </div>
        </div>
        <!-- <div
            class="bg-dark text-center text-light py-2 font-weight-bold"
            style="margin-top: 350px;"
        >
            {{ new Date().getFullYear() }} | PEMERINTAH PROVINSI SUMATERA UTARA
        </div> -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import PageOptions from "@/config/PageOptions.vue";
import Logo from "@/assets/images/logoSumut.png";
import { mapState } from "vuex";

export default {
    data() {
        return {
            Logo: Logo,
            form: new Form({
                unit_pd_id: "",
                nik: "",
                nip: "",
                nama: "",
                jenis_kelamin: "",
                tmp_lahir: "",
                tgl_lahir: "",
                nomp: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: ""
            }),
            errors: [],
            prov_id: "",
            isLoading: false,
            // datepicker config
            options: {
                format: "YYYY-MM-DD",
                useCurrent: true,
                minDate: "1900-01-01"
            },
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: ""
            },
            perangkat: {
                perangkat_pd_id: ""
            },
            show: false,
            selesai: false
        };
    },
    created() {
        PageOptions.pageEmpty = true;
        document.body.className = "bg-light-transparent-2";
    },
    beforeRouteLeave(to, from, next) {
        PageOptions.pageEmpty = false;
        document.body.className = "";
        next();
    },
    computed: {
        ...mapState(["PerangkatReg", "WilayahReg"])
    },
    mounted() {
        this.$store.dispatch("WilayahReg/getProvinsi");
        this.$store.dispatch("PerangkatReg/getPerangkat");
    },
    methods: {
        // store data pegawai
        store() {
            this.isLoading = true;
            this.show = true;
            this.form
                .post("/api/registrasi/pegawai")
                .then(response => {
                    if (this.form.successful) {
                        this.isLoading = false;
                        this.show = false;
                        this.form.reset();
                        this.form.clear();
                        this.wilayah = {
                            provinsi: "",
                            kabkota: "",
                            kecamatan: ""
                        };
                        this.perangkat = {
                            perangkat_pd_id: ""
                        };
                        this.selesai = true;
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    this.show = false;
                    this.selesai = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        // wilayah
        // provinsi
        onProvinsi(id) {
            if (id) {
                this.$store.dispatch("WilayahReg/getKabkota", id);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.$store.commit("WilayahReg/kabkota", []);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kabkota
        onKabkota(id) {
            if (id) {
                this.$store.dispatch("WilayahReg/getKecamatan", id);
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.$store.commit("WilayahReg/kecamatan", []);
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            }
        },
        // kecamatan
        onKecamatan(id) {
            if (id) {
                this.$store.dispatch("WilayahReg/getKelurahan", id);
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.$store.commit("WilayahReg/kelurahn", []);
                this.form.wilayah_kelurahan_id = "";
            }
        },

        // input perangkat
        inputPerangkat(id) {
            if (id) {
                this.$store.dispatch("PerangkatReg/getUnit", id);
                this.form.unit_pd_id = "";
            } else {
                this.$store.commit("PerangkatReg/unit", []);
                this.form.unit_pd_id = "";
            }
        },
        login () {
            this.$router.push({ name: 'Login' })
        }
    }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
