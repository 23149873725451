var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register register-with-news-feed"},[_c('div',{staticClass:"news-feed bg-teal-transparent-2"},[_c('div',{staticClass:"text-center pb-3",staticStyle:{"margin-top":"160px"}},[_c('img',{attrs:{"src":_vm.logoSumut,"width":"200","alt":"logoSumut"}})]),_vm._m(0)]),_c('div',{staticClass:"right-content"},[_vm._m(1),_c('div',{staticClass:"register-content"},[_c('form',{staticClass:"margin-bottom-0",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.register($event)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('alert-error',{staticClass:"border-danger mb-2",attrs:{"form":_vm.form}}),_vm._m(2),_c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has('name'),
                                },attrs:{"type":"text","placeholder":"Masukkan nama","disabled":_vm.isDisable},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.form.errors.has('name'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.name[0]))]):_vm._e()])]),_vm._m(3),_c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has('email'),
                                },attrs:{"type":"email","placeholder":"Masukkan email","disabled":_vm.isDisable},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.form.errors.has('email'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.email[0]))]):_vm._e()])]),_vm._m(4),_c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has('password'),
                                },attrs:{"type":"password","placeholder":"Masukkan password","disabled":_vm.isDisable},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(_vm.form.errors.has('password'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.password[0]))]):_vm._e()])]),_vm._m(5),_c('div',{staticClass:"row m-b-15"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],class:{
                                    'form-control': true,
                                    'is-invalid': _vm.form.errors.has('password'),
                                },attrs:{"type":"password","placeholder":"Konfirmasi password","disabled":_vm.isDisable},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),(_vm.form.errors.has('password'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.password[0]))]):_vm._e()])]),_c('div',{staticClass:"register-buttons"},[_c('b-button',{attrs:{"variant":"success","block":"","type":"submit","disabled":_vm.isDisable}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"variant":"light","small":"","label":"loading"}})],1):_vm._e(),_vm._v(" Daftar ")])],1),_c('div',{staticClass:"m-t-20 p-b-40 text-inverse"},[_c('div',[_vm._v(" Sudah punya akun? ")]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-md-6"},[_c('b-button',{attrs:{"variant":"outline-success","block":""},on:{"click":_vm.toLogin}},[_vm._v("Login")])],1),_c('span',{staticClass:"col-md-6"},[_c('b-button',{attrs:{"variant":"outline-success","block":""},on:{"click":_vm.toVerifikasi}},[_vm._v("Kirim Ulang Verifikasi")])],1)])]),_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v(" © Pemerintah Provinsi Sumatera Utara | All Right Reserved 2020 ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"d-flex justify-content-center"},[_vm._v(" PEMERINTAH PROVINSI SUMATERA UTARA ")]),_c('h3',{staticClass:"d-flex justify-content-center"},[_vm._v(" BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH ")]),_c('p',{staticClass:"font-weight-bold d-flex justify-content-center",staticStyle:{"font-size":"15px"}},[_vm._v(" Jl. Sisingamangaraja Km 5,5 Medan ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"register-header"},[_vm._v(" Registrasi Pengguna Wajib Retribusi "),_c('small',[_vm._v("Silahkan isi form di bawah ini untuk membuat akun baru.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"control-label"},[_vm._v("Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"control-label"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"control-label"},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"control-label"},[_vm._v("Konfirmasi Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }